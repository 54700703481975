<template>
  <UDropdown
    v-if="user"
    mode="hover"
    :items
    :ui="{ width: 'w-full', item: { disabled: 'cursor-text select-text' } }"
    :popper="{ strategy: 'absolute', placement: 'top' }"
    class="w-full"
  >
    <template #default="{ open }">
      <UButton
        color="gray"
        variant="ghost"
        class="w-full"
        :label="user.name"
        :class="[open && 'bg-gray-50 dark:bg-gray-800']"
      >
        <template #leading>
          <UAvatar :alt="user.name" size="sm" />
        </template>

        <template #trailing>
          <UIcon name="i-heroicons-ellipsis-vertical" class="ml-auto h-5 w-5" />
        </template>
      </UButton>
    </template>

    <template #account>
      <div class="text-left">
        <p>Signed in as</p>
        <p class="truncate font-medium text-gray-900 dark:text-white">
          {{ user.email }}
        </p>
      </div>
    </template>
  </UDropdown>
</template>

<script setup>
const { isHelpSlideoverOpen, isSearchModalOpen } = useDashboard();

const { metaSymbol } = useShortcuts();

const colorMode = useColorMode();
const { isStatsCardSlideoverOpen } = useDashboard();

const { logout, user } = useSanctumAuth();
const themeStore = useThemeStore();
async function logUserOut() {
  themeStore.reset();
  await logout();
}

const items = computed(() => [
  [
    {
      slot: "account",
      label: "",
      disabled: true,
    },
  ],
  [
    {
      label: "My profile",
      icon: "i-heroicons-user-circle",
      to: `/users/${user.value.id}`,
    },
    {
      label: "My weekly stats",
      icon: "i-heroicons-presentation-chart-line",
      shortcuts: ["S", "S"],
      click: () => {
        isStatsCardSlideoverOpen.value = true;
      },
    },
    {
      label: "Search",
      icon: "i-heroicons-magnifying-glass",
      shortcuts: [metaSymbol.value, "K"],
      click: () => {
        isSearchModalOpen.value = true;
      },
    },
    {
      label: "Help & Support",
      icon: "i-heroicons-question-mark-circle",
      shortcuts: ["?"],
      click: () => (isHelpSlideoverOpen.value = true),
    },
  ],
  [
    {
      label: "Light mode",
      icon: "i-heroicons-sun",
      click: () => (colorMode.preference = "light"),
    },
    {
      label: "Dark mode",
      icon: "i-heroicons-moon",
      click: () => (colorMode.preference = "dark"),
    },
  ],
  // [
  //   {
  //     label: "Documentation",
  //     icon: "i-heroicons-book-open",
  //     to: "https://ui.nuxt.com/pro/getting-started",
  //     target: "_blank",
  //   },
  //   {
  //     label: "GitHub repository",
  //     icon: "i-simple-icons-github",
  //     to: "https://github.com/nuxt-ui-pro/dashboard",
  //     target: "_blank",
  //   },
  //   {
  //     label: "Buy Nuxt UI Pro",
  //     icon: "i-heroicons-credit-card",
  //     to: "https://ui.nuxt.com/pro/purchase",
  //     target: "_blank",
  //   },
  // ],
  [
    {
      label: "Sign out",
      icon: "i-heroicons-arrow-left-on-rectangle",
      click: logUserOut,
    },
  ],
]);
</script>
