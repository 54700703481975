<template>
  <UModal
    :ui="{
      width: 'w-full sm:max-w-2xl',
    }"
  >
    <UCard>
      <template #header>Edit project</template>

      <fieldset class="space-y-4">
        <UFormGroup
          label="Title"
          name="title"
        >
          <UInput v-model="project.title" />
        </UFormGroup>

        <UFormGroup
          label="Engagement Date"
          name="engaged_at"
        >
          <UInput
            v-model="project.project.engaged_at"
            type="date"
          />
        </UFormGroup>

        <UFormGroup
          label="Select target company"
          name="target_company"
          :required="true"
        >
          <AsyncSearchDropdown
            v-model="project.target_company"
            label="name"
            track-by="name"
            :multiple="false"
            placeholder="Select target company"
            source="companies"
          />
        </UFormGroup>

        <UFormGroup
          label="Select associated deal"
          name="deal_id"
        >
          <multiselect
            v-model="project.deal"
            :options="deals.data"
            label="title"
            track-by="title"
            :multiple="false"
            placeholder="Select a deal"
          />
        </UFormGroup>
      </fieldset>
      <template #footer>
        <div class="flex w-full items-center justify-between">
          <UButton
            @click="deleteProject"
            color="red"
            variant="ghost"
            label="Delete"
          />
          <UButton
            color="green"
            @click="updateProject"
            label="Update"
          />
        </div>
      </template>
    </UCard>
  </UModal>
</template>

<script setup>
import Multiselect from "vue-multiselect";

const props = defineProps({
  project: {
    type: Object,
    required: true,
  },
});

const deals = await useApiDropdownFetch("deals");

const emit = defineEmits(["updated", "deleted", "error"]);

async function updateProject() {
  try {
    const response = await useApiPut(`projects/${props.project.project.id}`, {
      body: props.project,
    });

    if (response.success) {
      emit("updated");
    }
  } catch (error) {
    emit("error", error);
  }
}

async function deleteProject() {
  try {
    const response = await useApiDelete(`projects/${props.project.project.id}`);

    if (response.success) {
      emit("deleted");
    }
  } catch (error) {
    emit("error", error);
  }
}
</script>
