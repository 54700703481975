<template>
  <UModal>
    <UCard>
      <template #header>
        <h2 class="text-lg font-semibold">Add a task to {{ data.title }}</h2>
      </template>

      <fieldset class="space-y-4">
        <UFormGroup label="Title">
          <UInput v-model="newTask.title" />
        </UFormGroup>

        <UFormGroup label="Assignee" name="assignee">
          <multiselect
            id="record.users"
            v-model="newTask.users"
            :options="users.data"
            label="name"
            track-by="name"
            :multiple="true"
            placeholder="Select user"
          />
        </UFormGroup>

        <UFormGroup label="Due Date" name="due_date">
          <UInput v-model="newTask.due_date" type="datetime-local" />
        </UFormGroup>

        <UFormGroup label="Priority" name="priority">
          <USelect
            v-model="newTask.task_priority_id"
            :options="priorities.data"
            option-attribute="title"
            value-attribute="id"
          />
        </UFormGroup>

        <UFormGroup label="Description" name="description">
          <UTextarea v-model="newTask.description" />
        </UFormGroup>

        <UFormGroup label="Links" name="links">
          <UTextarea v-model="newTask.links" />
        </UFormGroup>
      </fieldset>

      <template #footer>
        <div class="flex justify-between">
          <UButton
            @click="$emit('close')"
            color="red"
            variant="ghost"
            label="Cancel"
          />
          <UButton @click="addTask" color="green" label="Add task" />
        </div>
      </template>
    </UCard>
  </UModal>
</template>

<script setup>
import Multiselect from "vue-multiselect";
const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const newTask = ref({
  title: null,
  users: [],
  due_date: null,
  task_type_id: null,
  project_id: props.data.id,
  task_priority_id: null,
  description: null,
  links: null,
});

const priorities = await useApiFetch("task-priorities");
const users = await useApiDropdownFetch("internal-users");

const emit = defineEmits(["success", "error", "close"]);

async function addTask() {
  try {
    const response = await useApiPost(
      `projects/${props.data.project.id}/tasks`,
      {
        body: newTask.value,
      },
    );
    if (response.success) {
      emit("success");
    }
  } catch (error) {
    emit("error", error);
  }
}
</script>
