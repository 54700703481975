<template>
  <AisSearchBox>
    <template #default="{ currentRefinement, refine }">
      <SearchInput
        :modelValue="currentRefinement"
        @update:modelValue="refine"
        @keydown.up.prevent="highlightPrevious"
        @keydown.down.prevent="highlightNext"
        @keydown.enter.prevent="goToLink"
      />
    </template>
  </AisSearchBox>
</template>

<script setup>
import { AisSearchBox } from "vue-instantsearch/vue3/es";

const model = defineModel({
  type: Number,
  default: -1,
});

function highlightPrevious() {
  model.value = Math.max(model.value - 1, -1);
}

function highlightNext() {
  model.value = Math.min(
    model.value + 1,
    document.querySelectorAll(".ais-Hits-item > a").length - 1,
  );
}

function goToLink() {
  const link = document.querySelectorAll(".ais-Hits-item > a")[model.value];
  if (link) {
    link.click();
  }
}
</script>
