<template>
  <UModal v-model="isOpen" prevent-close>
    <UCard>
      <template #header> Create new deal </template>

      <UFormGroup
        label="Deal Type"
        :required="true"
        name="deal_type"
        :error="dealTypeError"
      >
        <Multiselect
          v-model="deal.deal_type"
          :options="dealTypes.data"
          label="title"
          track-by="title"
          placeholder="Select deal type"
          :multiple="false"
          group-values="subcategories"
          group-label="title"
          @blur="validateDealType"
        />
      </UFormGroup>

      <UFormGroup label="Target Company" required :error="targetCompanyError">
        <AsyncSearchDropdown
          v-model="deal.dealCompanies"
          label="name"
          :multiple="false"
          placeholder="Select company"
          source="companies"
          @blur="validateTargetCompany"
        />
      </UFormGroup>

      <UFormGroup
        label="Deal Stage"
        name="deal_stage"
        required
        :error="dealStageError"
      >
        <Multiselect
          v-model="deal.deal_stage"
          :options="dealStages.data"
          label="title"
          track-by="title"
          placeholder="Select deal stage"
          :multiple="false"
          @blur="validateDealStage"
        />
      </UFormGroup>

      <UFormGroup label="Deal size ($mm)">
        <UInput v-model="deal.close_amount">
          <template #leading>$</template>
        </UInput>
      </UFormGroup>

      <UFormGroup label="Close Date">
        <UInput v-model="deal.close_date" type="date" />
      </UFormGroup>

      <UFormGroup label="News article link">
        <UInput v-model="deal.links.article" placeholder="Link (optional)" />
      </UFormGroup>

      <UFormGroup label="Description">
        <UTextarea v-model="deal.description" size="xl" />
      </UFormGroup>

      <template #footer>
        <div class="flex justify-between">
          <UButton color="gray" @click="$emit('close')" label="Cancel" />
          <UButton @click="createDeal" color="green" label="Create" />
        </div>
      </template>
    </UCard>
  </UModal>
</template>

<script setup>
import Multiselect from "vue-multiselect";

const isOpen = ref(false);

const dealTypes = await useApiDropdownFetch("deal-types");
const dealStages = await useApiDropdownFetch("deal-stages");
const dealTypeError = ref("");
const dealStageError = ref("");
const targetCompanyError = ref("");

const deal = ref({
  dealCompanies: [],
  deal_stage: null,
  deal_type: null,
  close_amount: null,
  close_date: null,
  links: [
    {
      article: null,
    },
  ],
  description: null,
});

const validateDealType = () => {
  if (!deal.value.deal_type) {
    dealTypeError.value = "Deal type is required";
    return false;
  }
  dealTypeError.value = "";
  return true;
};

const validateDealStage = () => {
  if (!deal.value.deal_stage) {
    dealStageError.value = "Deal stage is required";
    return false;
  }
  dealStageError.value = "";
  return true;
};

const validateTargetCompany = () => {
  if (!deal.value.dealCompanies || !deal.value.dealCompanies.id) {
    targetCompanyError.value = "A target company is required";
    return false;
  }
  targetCompanyError.value = "";
  return true;
};

watch(
  () => deal.value.deal_type,
  (newValue) => {
    if (newValue) {
      dealTypeError.value = "";
    }
  },
);

watch(
  () => deal.value.deal_stage,
  (newValue) => {
    if (newValue) {
      dealStageError.value = "";
    }
  },
);

watch(
  () => deal.value.dealCompanies,
  (newValue) => {
    if (newValue && newValue.id) {
      targetCompanyError.value = "";
    }
  },
  { deep: true },
);

const validateAll = () => {
  const dealTypeValid = validateDealType();
  const dealStageValid = validateDealStage();
  const targetCompanyValid = validateTargetCompany();
  return dealTypeValid && dealStageValid && targetCompanyValid;
};

const emit = defineEmits(["created", "error", "close"]);
async function createDeal() {
  if (!validateAll()) {
    return;
  }

  try {
    const response = await useApiPost("deals", {
      body: deal.value,
    });

    if (response.success) {
      deal.value = {
        target_company: [],
        deal_stage: null,
        deal_type: null,
        close_amount: null,
        close_date: null,
        links: [
          {
            article: null,
          },
        ],
        description: null,
      };
      emit("created");
    }
  } catch (error) {
    emit("error", error);
  }
}
</script>
