import { defineEmits } from "vue";

export async function uploadFiles(files, record, url) {
  // set up the request data
  let formData = new FormData();

  files.forEach((file) => {
    formData.append("files[]", file.file);
  });

  formData.append("description", record.description);
  formData.append("document_category_id", record.document_category.id);
  formData.append("document_category_title", record.document_category.title);

  if (record.expires_at) {
    formData.append("expires_at", record.expires_at);
  }

  if (record.signed_at) {
    formData.append("signed_at", record.signed_at);
  }

  let response = await useApiPost(url, { body: formData });

  // const emit = defineEmits(["uploaded"]);

  // emit("uploaded");

  return response;
}

export default function createUploader(url) {
  return {
    uploadFiles: function (files, record) {
      return uploadFiles(files, record, url);
    },
  };
}
