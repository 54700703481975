import { createSharedComposable } from "@vueuse/core";
import { is } from "date-fns/locale";

const _useDashboard = () => {
  const route = useRoute();
  const router = useRouter();
  const isHelpSlideoverOpen = ref(false);
 const isNotificationsSlideoverOpen = ref(false);
 const isStatsCardSlideoverOpen = ref(false);
  const isSearchModalOpen = ref(false);
  const highlightedIndex = ref(-1);

  defineShortcuts({
    "g-h": () => router.push("/"),
    "g-d": () => router.push("/deals"),
    "g-p": () => router.push("/prospects"),
    "g-c": () => router.push("/companies"),
    "g-i": () => router.push("/institutions"),
    "g-s": () => router.push("/settings"),
    "g-p-r": () => router.push("/prospects"),
    "?": () => (isHelpSlideoverOpen.value = true),
    "s-s": () => {
      isStatsCardSlideoverOpen.value = true;
    },
       n: () => (isNotificationsSlideoverOpen.value = true),
    meta_k: {
      usingInput: true,
      handler: () => {
        highlightedIndex.value = -1;
        isSearchModalOpen.value = true;
      },
    },
  });

  watch(
    () => route.fullPath,
    () => {
      isHelpSlideoverOpen.value = false;
     isNotificationsSlideoverOpen.value = false;
      isStatsCardSlideoverOpen.value = false;
      isSearchModalOpen.value = false;
      highlightedIndex.value = -1;
    },
  );
  return {
    isHelpSlideoverOpen,
    isStatsCardSlideoverOpen,
    isSearchModalOpen,
    highlightedIndex,
    isNotificationsSlideoverOpen
  };
};

export const useDashboard = createSharedComposable(_useDashboard);
