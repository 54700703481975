<template>
  <UModal>
    <UCard>
      <template #header>Create new institution</template>

      <fieldset class="space-y-4">
        <UFormGroup
          label="Name"
          name="name"
          required
          :error="nameError"
        >
          <UInput
            v-model="institution.name"
            @blur="validateName"
            :trailing-icon="
              nameError
                ? 'i-heroicons-exclamation-triangle-20-solid'
                : undefined
            "
          />
        </UFormGroup>

        <UFormGroup
          label="Type"
          name="types"
        >
          <multiselect
            v-model="institution.types"
            :options="institutionTypes.data"
            label="title"
            track-by="title"
            placeholder="Select type"
            multiple="true"
          />
        </UFormGroup>

        <UFormGroup
          label="City"
          name="city"
        >
          <UInput v-model="institution.address.city" />
        </UFormGroup>

        <UFormGroup
          label="State"
          name="state"
        >
          <UInput v-model="institution.address.state" />
        </UFormGroup>

        <UFormGroup
          label="Country"
          name="country"
        >
          <UInput v-model="institution.address.country" />
        </UFormGroup>

        <UFormGroup
          label="Background"
          name="background"
        >
          <UTextarea v-model="institution.background" />
        </UFormGroup>
      </fieldset>

      <template #footer>
        <div class="flex justify-between">
          <UButton
            color="gray"
            @click="$emit('close')"
            label="Cancel"
          />
          <UButton
            @click="createInstitution"
            color="green"
            label="Create"
          />
        </div>
      </template>
    </UCard>
  </UModal>
</template>

<script setup>
import Multiselect from "vue-multiselect";

const institutionTypes = await useApiDropdownFetch("institutional-types");
const nameError = ref("");

const institution = ref({
  name: null,
  types: [],
  address: [
    {
      city: null,
      state: null,
      country: null,
    },
  ],
  background: null,
});

const validateName = () => {
  if (!institution.value.name || institution.value.name.trim() === "") {
    nameError.value = "A name is required";
    return false;
  }
  nameError.value = "";
  return true;
};

watch(
  () => institution.value.name,
  (newValue) => {
    if (newValue) {
      nameError.value = "";
    }
  },
);

const emit = defineEmits(["created", "error", "close"]);
async function createInstitution() {
  if (!validateName()) {
    return;
  }

  try {
    const response = await useApiPost("institutions", {
      body: institution.value,
    });

    if (response.success) {
      institution.value = {
        name: null,
        types: [],
        address: [
          {
            city: null,
            state: null,
            country: null,
          },
        ],
        background: null,
      };
      emit("created");
    }
  } catch (error) {
    emit("error", error);
  }
}
</script>
