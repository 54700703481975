<template>
  <UDashboardPage>
    <UDashboardPanel grow>
      <PageNavbar title="Projects" />

      <UDashboardPanelContent>
        <UDashboardSection
          v-if="project"
          orientation="vertical"
          :ui="{ title: 'text-3xl flex items-end space-x-2' }"
        >
          <template #title>
            {{ project.data?.title }} {{ project.parent?.title }} Dashboard
          </template>
          <template #description>
            <div class="flex flex-col">
              <div>
                <span>Engaged on: </span>
                {{ formatDateWithoutTime(project.data?.project?.engaged_at) }}
              </div>
              <div>
                <span>Target company: </span>
                <span v-if="!project.data?.project?.target_company">
                  None
                </span>
                <UButton v-else color="amber" variant="link">
                  <ULink
                    :to="`/companies/${project.data?.project?.target_company?.id}`"
                  >
                    {{ project.data?.project?.target_company?.name }}
                    <UIcon name="i-heroicons-arrow-top-right-on-square" />
                  </ULink>
                </UButton>
              </div>
              <div>
                <span>Associated deal: </span>
                <span v-if="!project.data?.project?.deal"> None </span>
                <UButton color="amber" variant="link" v-else>
                  <ULink :to="`/deals/${project.data?.project?.deal?.id}`">
                    {{ project.data?.project?.deal?.company.name }}
                    {{ project.data?.project?.deal?.deal_type?.title }}
                    <UIcon name="i-heroicons-arrow-top-right-on-square" />
                  </ULink>
                </UButton>
              </div>
            </div>
          </template>
        </UDashboardSection>
        <UDashboardToolbar class="px-1.5 py-0">
          <template #left>
            <div>
              <UButton
                @click="openProjectEditModal"
                label="Edit project"
                trailingIcon="i-heroicons-pencil"
                color="white"
                size="md"
              />
            </div>
          </template>
          <template #right>
            <UDropdown :items>
              <UButton
                icon="i-heroicons-plus"
                color="white"
                label="Add to project"
                size="md"
              />
            </UDropdown>
          </template>
        </UDashboardToolbar>

        <ULandingSection
          :ui="{
            container: 'max-w-none gap-y-4 sm:gap-y-4 px-0 sm:px-0 lg:px-0',
            base: 'text-left items-start',
            wrapper: 'py-4 sm:py-4',
          }"
        >
          <ULandingGrid>
            <ULandingCard
              class="col-span-3 row-span-2 cursor-pointer"
              title="Company universe"
              :description="`${companiesCount} companies`"
              @click="setActiveTab(0)"
              color="amber"
            />
            <ULandingCard
              class="col-span-3 row-span-2 cursor-pointer"
              title="Associated documents"
              :description="`${documentsCount} documents`"
              @click="setActiveTab(2)"
              color="amber"
            />
            <ULandingCard
              class="col-span-3 row-span-2 cursor-pointer"
              title="Project Deliverables"
              :description="`${completedDeliverables}/${deliverables} tasks (${percentCompleted})%`"
              @click="setActiveTab(3)"
              color="amber"
            />
            <ULandingCard
              class="col-span-3 row-span-2 cursor-pointer"
              title="Project History"
              :description="`${eventsCount} events`"
              @click="setActiveTab(5)"
              color="amber"
            />
          </ULandingGrid>
        </ULandingSection>

        <UTabs
          v-model="selectedTab"
          :items="projectTabs"
          :default-index="selectedTab"
          @change="onChange"
        >
          <template #default="{ item }">
            <div class="relative flex items-center gap-2 truncate">
              <span class="truncate">{{ item.label }}</span>
            </div>
          </template>

          <template #company_universe>
            <LazyProjectCompanyUniverse
              :project-id="projectId"
              :data="project.data"
              :key="companyRefreshKey"
            />
          </template>
          <template #institution_universe>
            <LazyProjectInstitutionUniverse
              v-if="showProjectInstitutions"
              :project-id="projectId"
              :data="project.data"
              :key="institutionRefreshKey"
            />
          </template>
          <template #documents>
            <LazyProjectDocuments
              v-if="showProjectDocuments"
              :project-id="projectId"
              :key="documentRefreshKey"
            />
          </template>
          <template #deliverables>
            <LazyProjectDeliverables
              v-if="showProjectDeliverables"
              :project-id="projectId"
              :key="deliverableRefreshKey"
            />
          </template>
          <template #tasks>
            <LazyProjectTasks
              v-if="showProjectTasks"
              :project-id="projectId"
              :key="taskRefreshKey"
            />
          </template>
          <template #project_history>
            <LazyProjectEvents
              v-if="showProjectEvents"
              :project-id="projectId"
              :data="project.data"
              :key="eventRefreshKey"
            />
          </template>
        </UTabs>
      </UDashboardPanelContent>
    </UDashboardPanel>
  </UDashboardPage>
</template>

<script setup>
import { projectTabs } from "@/helpers/tabs";

const showProjectInstitutions = ref(false);
const showProjectDocuments = ref(false);
const showProjectDeliverables = ref(false);
const showProjectTasks = ref(false);
const showProjectEvents = ref(false);

function onChange(index) {
  showProjectInstitutions.value = index === 1;
  showProjectDocuments.value = index === 2;
  showProjectDeliverables.value = index === 3;
  showProjectTasks.value = index === 4;
  showProjectEvents.value = index === 5;
}

const selectedTab = ref(0);
function setActiveTab(tab) {
  selectedTab.value = tab;
  showProjectDocuments.value = tab === 2;
  showProjectDeliverables.value = tab === 3;
  showProjectEvents.value = tab === 5;
}

const institutionRefreshKey = ref(0);
const companyRefreshKey = ref(0);
const documentRefreshKey = ref(0);
const deliverableRefreshKey = ref(0);
const taskRefreshKey = ref(0);
const eventRefreshKey = ref(0);

const route = useRoute();
const projectId = route.params.id;
const { data: project, refresh } = await useAsyncData(
  `projects:${projectId}`,
  async () => {
    const [project] = await Promise.all([useApiFetch(`projects/${projectId}`)]);
    return { data: project.data };
  },
);

useHead({
  title:
    `${project.value.data.title} ${project.value.data.parent.title}` ||
    "Project",
});

const companiesCount = computed(
  () => project.value?.data?.companies_count || 0,
);
const documentsCount = computed(
  () => project.value?.data?.documents_count || 0,
);
const eventsCount = computed(() => project.value?.data?.events_count || 0);

const deliverables = computed(
  () =>
    project.value?.data?.tasks.filter(
      (t) => t.task_type?.title === "Project Deliverable",
    ).length || 0,
);

const completedDeliverables = computed(
  () =>
    project.value?.data?.tasks.filter(
      (t) => t.completed_at && t.task_type?.title === "Project Deliverable",
    ).length || 0,
);

const percentCompleted = computed(() =>
  Math.round((completedDeliverables.value / deliverables.value) * 100),
);

const items = [
  [
    {
      label: "Add company",
      icon: "i-heroicons-building-office-2",
      click: () => openProjectCompanyAddModal(),
    },
    {
      label: "Add institution",
      icon: "i-heroicons-building-office",
      click: () => openProjectInstitutionAddModal(),
    },
    {
      label: "Add document",
      icon: "i-heroicons-paper-clip",
      click: () => openProjectDocumentAddModal(),
    },
    {
      label: "Add deliverable",
      icon: "i-heroicons-list-bullet-solid",
      click: () => openProjectDeliverableAddModal(),
    },
    {
      label: "Add task",
      icon: "i-heroicons-clipboard-document-list",
      click: () => openProjectTaskAddModal(),
    },
    // {
    //   label: "Add event",
    //   icon: "i-heroicons-calendar",
    //   click: () => openProjectEventAddSlideover(),
    // },
  ],
];

import {
  ModalProjectCompanyAdd,
  ModalProjectDocumentAdd,
  ModalProjectDeliverableAdd,
  ModalProjectTaskAdd,
  SlideoverProjectEventAdd,
  ModalProjectInstitutionAdd,
} from "#components";

const slideover = useSlideover();
const modal = useModal();
const toast = useToast();

function openProjectCompanyAddModal() {
  modal.open(ModalProjectCompanyAdd, {
    data: project.value.data,
    onError: (error) => {
      toast.add({ title: `Error adding company: ${error}`, color: "red" });
    },
    onSuccess: () => {
      refresh();
      companyRefreshKey.value += 1;
      modal.close();
      toast.add({ title: "Company added", color: "green" });
    },
    onClose: () => {
      modal.close();
    },
  });
}

function openProjectInstitutionAddModal() {
  modal.open(ModalProjectInstitutionAdd, {
    data: project.value.data,
    onError: (error) => {
      toast.add({ title: `Error adding institution: ${error}`, color: "red" });
    },
    onSuccess: () => {
      refresh();
      institutionRefreshKey.value += 1;
      modal.close();
      toast.add({ title: "Institution added", color: "green" });
    },
    onClose: () => {
      modal.close();
    },
  });
}

function openProjectDocumentAddModal() {
  modal.open(ModalProjectDocumentAdd, {
    data: project.value.data,
    onError: (error) => {
      toast.add({ title: `Error adding document: ${error}`, color: "red" });
    },
    onUploaded: () => {
      refresh();
      documentRefreshKey.value += 1;
      modal.close();
      toast.add({ title: "Document added", color: "green" });
    },
    onClose: () => {
      modal.close();
    },
  });
}

function openProjectDeliverableAddModal() {
  modal.open(ModalProjectDeliverableAdd, {
    data: project.value.data,
    onError: (error) => {
      toast.add({ title: `Error adding deliverable: ${error}`, color: "red" });
    },
    onSuccess: () => {
      refresh();
      deliverableRefreshKey.value += 1;
      modal.close();
      toast.add({ title: "Deliverable added", color: "green" });
    },
    onClose: () => {
      modal.close();
    },
  });
}

function openProjectTaskAddModal() {
  modal.open(ModalProjectTaskAdd, {
    data: project.value.data,
    onError: (error) => {
      toast.add({ title: `Error adding task: ${error}`, color: "red" });
    },
    onSuccess: () => {
      refresh();
      taskRefreshKey.value += 1;
      modal.close();
      toast.add({ title: "Task added", color: "green" });
    },
    onClose: () => {
      modal.close();
    },
  });
}

function openProjectEventAddSlideover() {
  slideover.open(SlideoverProjectEventAdd, {
    data: project.value.data,
    onError: (error) => {
      toast.add({ title: `Error adding event: ${error}`, color: "red" });
    },
    onCreated: () => {
      refresh();
      eventRefreshKey.value += 1;
      slideover.close();
      toast.add({ title: "Event added", color: "green" });
    },
  });
}

import { ModalProjectEdit } from "#components";
const router = useRouter();
function openProjectEditModal() {
  modal.open(ModalProjectEdit, {
    project: project.value.data,
    onError: (error) => {
      toast.add({ title: error, color: "red" });
    },
    onUpdated: () => {
      refresh();
      toast.add({ title: "Project updated", color: "green" });
      modal.close();
    },
    onDeleted: () => {
      toast.add({ title: "Project deleted", color: "green" });
      modal.close();
      router.push("/projects");
    },
  });
}
</script>
