<template>
  <AisInstantSearch
    index-name="users"
    :search-client="searchClient"
  >
    <slot name="default" />
  </AisInstantSearch>
</template>

<script setup>
import { instantMeiliSearch } from "@meilisearch/instant-meilisearch";
import { AisInstantSearch } from "vue-instantsearch/vue3/es";

const themeStore = useThemeStore();
const { tenantToken } = storeToRefs(themeStore);

const { host, options } = useRuntimeConfig().public.meilisearch;

const searchClient = instantMeiliSearch(
  host,
  tenantToken.value,
  options,
).searchClient;
</script>

<style>
.uDivider:has(~ .ais-Hits--empty) {
  @apply hidden;
}
</style>
