<template>
  <UModal>
    <UCard>
      <template #header>Create new project</template>

      <fieldset class="space-y-4">
        <UFormGroup
          label="Title"
          name="title"
          required
          :error="titleError"
        >
          <UInput
            v-model="project.title"
            @blur="validateTitle"
            :trailing-icon="
              titleError
                ? 'i-heroicons-exclamation-triangle-20-solid'
                : undefined
            "
          />
        </UFormGroup>

        <UFormGroup
          label="Select target company"
          name="target_company"
          required
          :error="targetCompanyError"
        >
          <AsyncSearchDropdown
            v-model="project.target_company"
            label="name"
            :multiple="false"
            placeholder="Select target company"
            source="companies"
            @blur="validateTargetCompany"
          />
        </UFormGroup>

        <UFormGroup
          label="Select associated deal"
          name="deal_id"
        >
          <multiselect
            v-model="project.deal"
            :options="deals.data"
            label="title"
            track-by="title"
            :multiple="false"
            placeholder="Select a deal"
          />
        </UFormGroup>

        <UFormGroup
          label="Engagement date"
          name="engaged_at"
        >
          <UInput
            v-model="project.engaged_at"
            type="date"
          />
        </UFormGroup>

        <UFormGroup
          label="Comment"
          name="comment"
        >
          <UTextarea
            v-model="project.comment"
            size="xl"
          />
        </UFormGroup>
      </fieldset>

      <template #footer>
        <div class="flex justify-between">
          <UButton
            color="gray"
            @click="$emit('close')"
            label="Cancel"
          />
          <UButton
            color="green"
            @click="createProject"
            label="Create"
          />
        </div>
      </template>
    </UCard>
  </UModal>
</template>

<script setup>
import Multiselect from "vue-multiselect";
const titleError = ref("");
const targetCompanyError = ref("");

const project = ref({
  title: null,
  target_company: {},
  deal: {},
  engaged_at: null,
  comment: null,
});

const deals = await useApiDropdownFetch("deals");

// function customLabel({ title, stage }) {
//   return `${title} – ${stage}`;
// }

const validateTitle = () => {
  if (!project.value.title || project.value.title.trim() === "") {
    titleError.value = "A title is required";
    return false;
  }
  titleError.value = "";
  return true;
};

const validateTargetCompany = () => {
  if (!project.value.target_company || !project.value.target_company.id) {
    targetCompanyError.value = "A target company is required";
    return false;
  }
  targetCompanyError.value = "";
  return true;
};

watch(
  () => project.value.title,
  (newValue) => {
    if (newValue) {
      titleError.value = "";
    }
  },
);

watch(
  () => project.value.target_company,
  (newValue) => {
    if (newValue) {
      targetCompanyError.value = "";
    }
  },
);

const validateAll = () => {
  const titleValid = validateTitle();
  const targetCompanyValid = validateTargetCompany();
  return titleValid && targetCompanyValid;
};

const emit = defineEmits(["created", "error", "close"]);
async function createProject() {
  if (!validateAll()) {
    return;
  }

  try {
    const response = await useApiPost("projects", {
      body: project.value,
    });

    if (response.success) {
      project.value = {
        title: null,
        target_company: {},
        deal: {},
        engaged_at: null,
        comment: null,
      };
      emit("created");
    }
  } catch (error) {
    emit("error", error);
  }
}
</script>
