export default defineNuxtRouteMiddleware((to, from) => {
  const { user } = useSanctumAuth();

  const filtered = [
    "access-denied",
    "login",
    "verify-email",
    "forgot-password",
    "reset-password",
    "two-factor-challenge",
  ];

  if (filtered.includes(to.path.name)) {
    return;
  }

  if (!(!!user && !!user.is_admin)) {
    return redirect("/access-denied");
  }
});
