<template>
  <UDashboardLayout>
    <UDashboardPanel
      id="crm-sidebar"
      :width="240"
      collapsible
      :resizable="{ min: 200, max: 300, storage: 'local' }"
    >
      <UDashboardNavbar
        class="!border-transparent"
        :ui="{ left: 'flex-1' }"
      >
        <template #left>
          <!-- ~/components/PageLogo.vue -->
          <PageLogo />
        </template>
      </UDashboardNavbar>

      <UDashboardSidebar>
        <template #header>
          <UButton
            icon="i-heroicons-magnifying-glass"
            color="gray"
            class="flex items-center justify-between"
            @click="openSearchModal"
            >Search...
            <span>
              <UKbd>{{ metaSymbol }}</UKbd>
              <UKbd>K</UKbd>
            </span>
          </UButton>
        </template>

        <UDashboardSidebarLinks :links="defaultLinks" />

        <UDivider label="Business" />

        <UDashboardSidebarLinks :links="businessLinks" />

        <UDivider label="Tools" />

        <UDashboardSidebarLinks :links="toolsLinks" />

        <div class="flex-1" />

        <UDashboardSidebarLinks :links="footerLinks" />

        <UDivider class="sticky bottom-0" />

        <template #footer>
          <!-- ~/components/UserDropdown.vue -->
          <UserDropdown />
        </template>
      </UDashboardSidebar>
    </UDashboardPanel>

    <slot />

    <!-- ~/components/HelpSlideover.vue -->
    <HelpSlideover />
    <!-- ~/components/NotificationsSlideover.vue -->
    <NotificationsSlideover />
    <!-- ~/components/Modal/BaseSearch.vue -->
    <StatsCardSlideover />
    <UModal
      v-model="isSearchModalOpen"
      :overlay="true"
      :ui="{ width: 'sm:max-w-2xl' }"
    >
      <SearchWrapper>
        <SearchBar v-model="highlightedIndex" />
        <SearchConfig />
        <UDivider label="Users" />
        <SearchResults
          :highlighted-index
          section="users"
        />
        <SearchIndexWrapper index-name="deals">
          <SearchResults
            :highlighted-index
            section="deals"
          />
        </SearchIndexWrapper>
        <SearchIndexWrapper index-name="companies">
          <SearchResults
            :highlighted-index
            section="companies"
          />
        </SearchIndexWrapper>
        <SearchIndexWrapper index-name="institutions">
          <SearchResults
            :highlighted-index
            section="institutions"
          />
        </SearchIndexWrapper>
        <button className="h-0 w-0 overflow-hidden flow-root" />
      </SearchWrapper>
    </UModal>
  </UDashboardLayout>
</template>

<script setup>
const { metaSymbol } = useShortcuts();

const themeStore = useThemeStore();
// set the theme
themeStore.set();

/**
 * NAVIGATION
 */
const route = useRoute();
const { isHelpSlideoverOpen, isSearchModalOpen, highlightedIndex } =
  useDashboard();

function openSearchModal() {
  highlightedIndex.value = -1;
  isSearchModalOpen.value = true;
}

const defaultLinks = [
  {
    id: "home",
    label: "Home",
    icon: "i-heroicons-home",
    to: "/",
    tooltip: {
      text: "Home",
      shortcuts: ["G", "H"],
    },
  },
];

const businessLinks = [
  {
    id: "prospects",
    label: "Prospects",
    icon: "i-heroicons-user-group",
    defaultOpen: true,
    to: "/prospects",
    children: [
      {
        label: "My lists",
        to: "/prospects",
        exact: true,
        tooltip: {
          text: "Home",
          shortcuts: ["G", "P", "R"],
        },
      },
      {
        label: "Outreach analytics",
        to: "/prospects/outreach-analytics",
      },
      {
        label: "Referrals",
        to: "/prospects/referrals",
      },
    ],
  },
  {
    id: "deals",
    label: "Deals",
    icon: "i-heroicons-currency-dollar",
    to: "/deals",
    tooltip: {
      text: "Deals",
      shortcuts: ["G", "D"],
    },
  },
  {
    id: "projects",
    label: "Projects",
    icon: "i-heroicons-paper-clip",
    to: "/projects",
    tooltip: {
      text: "Projects",
      shortcuts: ["G", "P"],
    },
  },
  {
    id: "companies",
    label: "Companies",
    icon: "i-heroicons-building-office-2",
    to: "/companies",
    tooltip: {
      text: "Companies",
      shortcuts: ["G", "C"],
    },
  },
  {
    id: "institutions",
    label: "Institutions",
    icon: "i-heroicons-building-library",
    to: "/institutions",
    tooltip: {
      text: "Institutions",
      shortcuts: ["G", "I"],
    },
  },
  {
    id: "compliance",
    label: "Compliance",
    icon: "i-heroicons-shield-check",
    defaultOpen: route.path.startsWith("/compliance"),
    to: "/compliance",
    children: [
      {
        label: "Marketing Materials",
        to: "/compliance",
        exact: true,
      },
      {
        label: "PPM Logs",
        to: "/compliance/ppm-logs",
      },
      {
        label: "Restricted Lists",
        to: "/compliance/dnc-list",
      },
      {
        label: "AML Resources",
        to: "/compliance/aml-resources",
      },
    ],
  },
  {
    id: "tasks",
    label: "Tasks",
    icon: "i-heroicons-clipboard-document-check",
    to: "/tasks",
    tooltip: {
      text: "Tasks",
      shortcuts: ["G", "T"],
    },
  },
  {
    id: "documents",
    label: "Documents",
    icon: "i-heroicons-document-text",
    to: "/documents",
    tooltip: {
      text: "Documents",
      shortcuts: ["G", "D"],
    },
  },
  // {
  //   id: "settings",
  //   label: "Settings",
  //   to: "/settings",
  //   icon: "i-heroicons-cog-8-tooth",
  //   children: [
  //     {
  //       label: "General",
  //       to: "/settings",
  //       exact: true,
  //     },
  //     {
  //       label: "Members",
  //       to: "/settings/members",
  //     },
  //     {
  //       label: "Notifications",
  //       to: "/settings/notifications",
  //     },
  //   ],
  //   tooltip: {
  //     text: "Settings",
  //     shortcuts: ["G", "S"],
  //   },
  // },
];

const toolsLinks = [
  {
    id: "tags",
    label: "Tags",
    icon: "i-heroicons-tag",
    to: "/tags",
    tooltip: {
      text: "Tags",
      shortcuts: ["G", "T"],
    },
  },
  {
    id: "merge",
    label: "Merge",
    icon: "i-heroicons-code-bracket-square",
    to: "/merge",
    tooltip: {
      text: "Merge",
      shortcuts: ["G", "M"],
    },
  },
  {
    id: "outreach",
    label: "Outreach",
    icon: "i-heroicons-chat-bubble-left-right",
    to: "/outreach",
    tooltip: {
      text: "Outreach",
      shortcuts: ["G", "O"],
    },
  },
  // calendar
  // {
  //   id: "calendar",
  //   label: "Calendar",
  //   icon: "i-heroicons-calendar",
  //   to: "/calendar",
  //   tooltip: {
  //     text: "Calendar",
  //     shortcuts: ["G", "C"],
  //   },
  // },
  // lists
  // {
  //   id: "lists",
  //   label: "Lists",
  //   icon: "i-heroicons-list-bullet",
  //   to: "/lists",
  //   tooltip: {
  //     text: "Lists",
  //     shortcuts: ["G", "L"],
  //   },
  // },
  // research
  {
    id: "research",
    label: "Research",
    icon: "i-heroicons-light-bulb",
    to: "/research",
    tooltip: {
      text: "Research",
      shortcuts: ["G", "R"],
    },
  },
  {
    id: "analytics",
    label: "Analytics",
    icon: "i-heroicons-chart-pie",
    to: "/analytics",
    tooltip: {
      text: "Analytics",
      shortcuts: ["G", "A"],
    },
  },
];

const footerLinks = [
  {
    label: "Settings",
    icon: "i-heroicons-cog-8-tooth",
    to: "/settings",
  },
  {
    label: "Help & Support",
    icon: "i-heroicons-question-mark-circle",
    click: () => (isHelpSlideoverOpen.value = true),
  },
];

// const combinedLinks = [...defaultLinks, ...businessLinks, ...toolsLinks];

// const groups = [
//   {
//     key: "links",
//     label: "Go to",
//     commands: combinedLinks.map((link) => ({
//       ...link,
//       shortcuts: link.tooltip?.shortcuts,
//     })),
//   },
// ];
</script>
