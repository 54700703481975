<template>
  <ULink to="/">
    <UColorModeImage
      class="mx-auto h-10 w-full object-contain"
      :light="theme.logos.sidebar.light"
      :dark="theme.logos.sidebar.dark"
      :alt="`${theme.name} CRM`"
      :title="`${theme.name} CRM`"
    />
  </ULink>
</template>

<script setup lang="ts">
const theme = {
  name: "Pasteworks CRM logo",
  logos: {
    sidebar: {
      dark: "/sidebar-logo-dark.svg",
      light: "/sidebar-logo-light.svg",
    },
  },
};
</script>
