<template>
  <ULink
    :to="link"
    class="group block px-6 py-2 hover:text-gray-400 lg:px-10"
    tabindex="0"
  >
    <span class="text-link group-hover:text-link-active flex">
      {{ item.name }}
    </span>
  </ULink>
</template>

<script setup>
const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  highlightedIndex: {
    type: Number,
    required: false,
  },
  section: {
    type: String,
    required: true,
    default: "users",
  },
});

const link = computed(() => `/${props.section}/${props.item.id}`);

const currentHighlightedIndex = computed(() => props.highlightedIndex);

watch(currentHighlightedIndex, () => {
  const results = document.querySelectorAll(".ais-Hits-item > a");

  // iterate over each and remove bg classes
  results.forEach((result) => {
    result.classList.remove("bg-gray-400/40");
  });

  if (props.highlightedIndex > -1) {
    results[props.highlightedIndex].classList.add("bg-gray-400/40");
  }
});
</script>
