import { defineStore } from "pinia";

import { defaultTheme } from "@/helpers/defaultTheme";

const toast = useToast();
const config = useRuntimeConfig();

export const useThemeStore = defineStore("theme", () => {
  const loaded = ref(false);
  const loading = ref(false);
  const theme = ref(defaultTheme);

  const clientId = computed(() => theme.value.id);
  const tenantToken = computed(() => theme.value.tenantToken);
  const properties = computed(() => theme.value.properties);

  async function set() {
    if (!loaded.value) {
      if (localStorage.getItem("theme")) {
        theme.value = JSON.parse(localStorage.getItem("theme"));
        loaded.value = true;
      }

      // fetch from API based on domain
      if (!localStorage.getItem("theme")) {
        await fetch();
      }
    }

    return Promise.resolve();
  }

  async function fetch() {
    loading.value = true;

    const property = "crm";

    try {
      const response = await $fetch(
        `/hub/themes/${window.location.hostname}?property=${property}`,
        { baseURL: config.public.apiUrl },
      );
      theme.value = response.data;
      localStorage.setItem("theme", JSON.stringify(response.data));
    } catch (error) {
      toast.add(error.message);
    } finally {
      loaded.value = true;
      loading.value = false;
    }
  }

  function reset() {
    localStorage.removeItem("theme");

    loading.value = false;
    loaded.value = false;
    theme.value = defaultTheme;
  }

  return {
    loaded,
    theme,
    clientId,
    tenantToken,
    properties,
    reset,
    set,
    fetch,
  };
});
