import validate from "/var/www/admin-next/node_modules/nuxt/dist/pages/runtime/validate.js";
import sanctum_58auth_58global from "/var/www/admin-next/node_modules/nuxt-auth-sanctum/dist/runtime/middleware/sanctum.global.js";
import manifest_45route_45rule from "/var/www/admin-next/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  sanctum_58auth_58global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "email-verified": () => import("/var/www/admin-next/middleware/emailVerified.js"),
  "is-admin": () => import("/var/www/admin-next/middleware/isAdmin.js"),
  "set-theme": () => import("/var/www/admin-next/middleware/setTheme.js")
}