export const defaultTheme = {
  id: "1",
  name: "Collage Partners",
  features: [],
  logos: {
    main: "logo.svg",
    sidebar: "logo-on-dark.svg",
    narrow: "logo-on-dark-standalone.svg",
  },
  page: {
    background: "#f3f4f6",
    heading: "#111827",
    text: "#111827",
  },
  sidebar: {
    background: "#1f2937",
    accent: "#111827",
    link: "#d1d5db",
    linkActive: "#ffffff",
    linkBackground: "#374151",
    linkBackgroundActive: "#111827",
  },
  form: {
    background: "#ffffff",
    labelText: "#374151",
    inputBorder: "#d1d5db",
    focus: "#22c55e",
  },
  links: {
    default: "#2563eb",
    active: "#1e40af",
  },
  tags: {
    default: {
      text: "#3730a3",
      textActive: "#dbeafe",
      background: "#e0e7ff",
      backgroundActive: "#3730a3",
    },
    create: {
      text: "#3730a3",
      textActive: "#e0f2fe",
      background: "#e0e7ff",
      backgroundActive: "#075985",
    },
  },
  buttons: {
    primary: {
      text: "#ffffff",
      textActive: "#ffffff",
      background: "#22c55e",
      backgroundActive: "#15803d",
      focus: "#22c55e",
      border: "#e5e7eb",
    },
    secondary: {
      text: "#ffffff",
      textActive: "#ffffff",
      background: "#000000",
      backgroundActive: "#1f2937",
      focus: "#000000",
      border: "#e5e7eb",
    },
    tertiary: {
      text: "#ffffff",
      textActive: "#ffffff",
      background: "#ffffff",
      backgroundActive: "#15803d",
      focus: "#22c55e",
      border: "#e5e7eb",
    },
    quaternary: {
      text: "#ffffff",
      textActive: "#ffffff",
      background: "#818cf8",
      backgroundActive: "#6366f1",
      focus: "#6366f1",
      border: "#e5e7eb",
    },
  },
  tabs: {
    text: "#6b7280",
    textActive: "#22c55e",
    background: "#ffffff",
    border: "#e5e7eb",
    borderActive: "#16a34a",
  },
  table: {
    background: "#ffffff",
    border: "#e5e7eb",
    divide: "#e5e7eb",
    oddBackground: "#ffffff",
    evenBackground: "#f9fafb",
    header: {
      background: "#ffffff",
      text: "#111827",
      textActive: "#22c55e",
      sortable: "#eab308",
      sortableActive: "#22c55e",
    },
  },
  emptyState: {
    background: "#f0fdf4",
    border: "#86efac",
  },
};
