export default defineAppConfig({
  ui: {
    primary: "gray",
    gray: "slate",

    link: {
      base: "text-primary-500 dark:text-gray-100 hover:underline",
      "active-class": "text-primary-500 dark:text-gray-100",
      "inactive-class":
        "text-gray-500 dark:text-gray-100 hover:text-gray-700 dark:hover:text-gray-100",
      activeClass: "text-primary",
      inactiveClass:
        "text-gray-500 dark:text-gray-100 hover:text-gray-700 dark:hover:text-gray-100",
    },
    dashboard: {
      page: {
        wrapper: "page",
      },
      section: {
        inner: "flex-auto",
      },
    },
    tabs: {
      list: {
        rounded: "rounded-none",
        background: "!bg-transparent",
        base: "border-b border-gray-100 dark:border-gray-800",
        shadow: "shadow-none",
        marker: {
          rounded: "rounded-none",
          background: "bg-transparent",
          shadow: "shadow-none",
        },
        tab: {
          rounded: "rounded-none",
          height: "h-9",
          active:
            "text-primary-500 dark:text-primary-200 border-b-2 border-primary-500 dark:border-primary-400",
          inactive: "border-b-2 border-transparent",
        },
      },
    },
    dropdown: {
      container: "z-50",
    },
    page: {
      header: {
        wrapper: "border-b-0 border-gray-100 dark:border-gray-800",
      },
    },
    table: {
      wrapper: "my-4",
      thead: "sticky top-0 bg-transparent backdrop-blur-[2px] isolate",
      default: {
        sortAscIcon: "i-heroicons-arrow-up-20-solid",
        sortDescIcon: "i-heroicons-arrow-down-20-solid",
        sortButton: {
          icon: "i-heroicons-sparkles-20-solid",
          color: "gray",
          variant: "outline",
          size: "2xs",
          square: false,
          ui: { rounded: "rounded-full" },
        },
      },
      td: {
        color: "gray",
        padding: "px-3 py-3",
      },
    },
    breadcrumb: {
      wrapper: "flex items-center",
    },
    divider: {
      wrapper: {
        base: "uDivider",
      },
      label: "text-primary-500 dark:text-primary-200 capitalize",
      default: {
        size: "2xs",
      },
    },
    badge: {
      base: "space-x-2",
    },
    selectMenu: {
      container: "z-40",
    },
  },
});
