import Echo from "laravel-echo";
import Pusher from "pusher-js";

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();

  if (typeof window !== "undefined") {
    window.Pusher = Pusher;

    window.$echo = new Echo({
      broadcaster: "pusher",
      key: config.public.pusherKey,
      cluster: config.public.pusherCluster,
      forceTLS: true,
      authorizer: (channel) => ({
        authorize: async (socketId, callback) => {
          try {
            const response = await fetch(
              `${config.public.sanctum.baseUrl}/api/broadcasting/auth`,
              {
                method: "POST",
                body: JSON.stringify({
                  socket_id: socketId,
                  channel_name: channel.name,
                }),
                headers: {
                  "Content-Type": "application/json",
                  "X-XSRF-TOKEN": useCookie("XSRF-TOKEN").value || "",
                },
                credentials: "include",
              },
            );
            const data = await response.json();
            callback(false, data);
          } catch (error) {
            callback(true, error);
          }
        },
      }),
    });
  }
});
