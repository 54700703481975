export default function useApiFetch(url, opts) {
  const apiUrl = useRuntimeConfig().public.apiUrl;
  // get client id from theme store
  const themeStore = useThemeStore();
  const { clientId } = storeToRefs(themeStore);

  const xsrfToken = useCookie("XSRF-TOKEN");

  let headers = {
    accept: "application/json",
    ...opts?.headers,
  };

  if (xsrfToken && xsrfToken.value !== null) {
    headers["X-XSRF-TOKEN"] = decodeURIComponent(xsrfToken.value);
  }

  return $fetch(`/admin/clients/${clientId.value}/${url}`, {
    baseURL: apiUrl,
    method: "POST",
    headers,
    credentials: "include",
    ...opts,
  });
}
