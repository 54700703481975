export default defineNuxtRouteMiddleware((to, from) => {
  const { user } = useSanctumAuth();

  const filtered = [
    "access-denied",
    "login",
    "verify-email",
    "forgot-password",
    "reset-password",
    "two-factor-challenge",
  ];

  if (filtered.includes(to.path.name)) {
    return;
  }

  // ensure logged in users' email is verified
  if (!user.email_verified_at) {
    return navigateTo("/verify-email");
  }
});
