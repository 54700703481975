export default function (value) {
  if (!value) {
    return 0;
  }

  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  return Number(value).toLocaleString(undefined, options);
}
