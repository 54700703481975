<template>
  <NuxtLoadingIndicator />
  <NuxtPwaManifest />

  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>

  <UNotifications />
  <UModals />
  <USlideovers />
</template>

<script setup>
const colorMode = useColorMode();

const color = computed(() =>
  colorMode.value === "dark" ? "#111827" : "white",
);

useHead({
  meta: [
    { charset: "utf-8" },
    { name: "viewport", content: "width=device-width, initial-scale=1" },
    { key: "theme-color", name: "theme-color", content: color },
    {
      hid: "description",
      name: "description",
      content: "Modern CRM for managing deals, investors and investments.",
    },
  ],
  link: [{ rel: "icon", href: "/favicon.ico" }],
  htmlAttrs: {
    lang: "en",
  },
  title: "Home page",
  titleTemplate: "%s | Pasteworks CRM",
});
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style>
.page a:hover,
.page a:active,
.page a:focus {
  @apply transition-all duration-200 ease-in-out;
}
</style>
