<template>
  <UModal>
    <UCard>
      <template #header>
        <h2 class="text-lg font-semibold">Add company to {{ data.title }}</h2>
      </template>

      <fieldset class="space-y-4">
        <UFormGroup
          name="company"
          label="Add existing company(s)"
        >
          <AsyncSearchDropdown
            v-model="companies"
            :multiple="true"
            placeholder="Select company"
            source="companies"
            label="name"
          />
        </UFormGroup>
      </fieldset>

      <template #footer>
        <div class="flex justify-between">
          <UButton
            @click="$emit('close')"
            color="red"
            variant="ghost"
            label="Cancel"
          />
          <UButton
            @click="addCompany"
            color="green"
            label="Add company"
          />
        </div>
      </template>
    </UCard>
  </UModal>
</template>

<script setup>
const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const companies = ref([]);

const emit = defineEmits(["success", "error", "close"]);

async function addCompany() {
  try {
    const response = await useApiPost(
      `projects/${props.data.project.id}/companies`,
      {
        body: {
          companies: companies.value,
        },
      },
    );
    if (response.success) {
      emit("success");
    }
  } catch (error) {
    emit("error", error);
  }
}
</script>
