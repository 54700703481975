<template>
  <div
    class="mx-auto my-20 flex flex-wrap items-center justify-center space-x-4"
  >
    <USkeleton
      class="h-12 w-12"
      :ui="{ rounded: 'rounded-full' }"
    />
    <div class="space-y-2">
      <USkeleton class="h-4 w-[250px]" />

      <USkeleton class="h-4 w-[200px]" />
    </div>

    <div class="w-full animate-pulse space-y-2 text-center">
      <slot>Loading...</slot>
    </div>
  </div>
</template>
