<template>
  <UDashboardSlideover
    v-model="isNotificationsSlideoverOpen"
    title="Notifications"
  >
    <BaseLoader v-if="loading">Loading notifications...</BaseLoader>

    <div
      v-if="!notifications?.length"
      class="p-4"
    >
      <p>No notifications yet, get some work done!</p>
    </div>

    <div
      v-else
      class="divide-y"
    >
      <div
        v-for="notification in notifications"
        :key="notification.id"
        class="relative flex cursor-pointer items-center gap-3 p-2"
        @click="markAsRead(notification)"
      >
        <div v-if="!notification.read_at">
          <UChip
            color="red"
            inset
          >
            <UAvatar
              :alt="notification.data.sender?.name"
              size="md"
              class="flex-none"
            />
          </UChip>
        </div>

        <div v-else>
          <UAvatar
            :alt="notification.data.sender?.name"
            size="md"
            class="flex-none"
          />
        </div>

        <div class="flex-1 text-sm">
          <p class="flex items-center justify-end">
            <time
              :datetime="notification.data.created_at"
              class="text-xs"
              v-text="formatTimeAgo(new Date(notification.created_at))"
            />
          </p>
          <p
            class="cursor-pointer hover:text-blue-600"
            @click="handleLinkClick(notification)"
          >
            {{ notification.data.message || notification.data.type }}
          </p>
        </div>
      </div>
    </div>
  </UDashboardSlideover>
</template>

<script setup>
import { useDashboard } from "@/composables/useDashboard";
import { formatTimeAgo } from "@vueuse/core";

const { isNotificationsSlideoverOpen } = useDashboard();
const router = useRouter();
const toast = useToast();

const {
  data: notifications,
  status,
  refresh,
} = await useAsyncData("notifications", async () => {
  const response = await useApiFetch("notifications");
  return response;
});

const loading = computed(() => status.value !== "success");

const checkTaskExists = async (taskId) => {
  try {
    await useApiFetch(`tasks/${taskId}`);
    return true;
  } catch (error) {
    return false;
  }
};

const markAsRead = async (notification, suppressToast = false) => {
  if (!notification.read_at) {
    try {
      const response = await useApiPost(
        `notifications/${notification.id}/read`,
      );

      if (response.success && response.notification) {
        const updatedNotification = response.notification;

        notifications.value = notifications.value.map((n) =>
          n.id === updatedNotification.id ? updatedNotification : n,
        );

        if (!suppressToast) {
          toast.add({
            title: "Notification marked as read",
            color: "green",
          });
        }
      } else {
        console.error("API failed to mark notification as read");
      }
    } catch (error) {
      console.error("Error marking notification as read:", error);
      toast.add({
        title: "Error marking notification as read",
        color: "red",
      });
    }
  }
};

const handleLinkClick = async (notification) => {
  await markAsRead(notification, true);

  if (!notification.data?.task?.id) return;

  if (notification.type.includes("TaskDeletedNotification")) {
    toast.add({ title: "This task has been deleted", color: "yellow" });
    return;
  }

  try {
    const taskExists = await checkTaskExists(notification.data.task.id);
    if (taskExists) {
      router.push(`/tasks/${notification.data.task.id}`);
    } else {
      toast.add({ title: "This task has been deleted", color: "yellow" });
    }
  } catch (error) {
    toast.add({ title: "Error checking task status", color: "red" });
  }
};

onMounted(() => {
  const channel = window.$echo?.private(
    `clients.${useSanctumAuth().user.value?.client_id}.notifications`,
  );

  if (channel) {
    const events = [
      "task.assigned",
      "task.updated",
      "task.completed",
      "task.reopened",
      "task.deleted",
    ];

    events.forEach((event) => {
      channel.listen(`.${event}`, (eventData) => {
        handleNewNotification(eventData, event);
      });
    });
  }
});

onUnmounted(() => {
  if (window.$echo && useSanctumAuth().user.value?.client_id) {
    window.$echo.leave(
      `clients.${useSanctumAuth().user.value.client_id}.notifications`,
    );
  }
});
</script>
