<template>
  <UDashboardNavbar :badge>
    <template #left>
      <UBreadcrumb :links />
    </template>
    <template #right>
      <UInput
        v-if="showFilter"
        ref="filterSearch"
        v-model="q"
        icon="i-heroicons-magnifying-glass"
        autocomplete="off"
        :placeholder="`Search by ${filterLabel}&hellip;`"
        class="hidden lg:block"
        @keydown.esc="$event.target.blur()"
      >
        <template #trailing>
          <UKbd value="/" />
        </template>
      </UInput>

      <UTooltip
        text="Stats Card"
        :shortcuts="['S', 'S']"
      >
        <UButton
          color="gray"
          variant="ghost"
          square
          @click="toggleStatsCardSlideover"
        >
          <UIcon
            name="i-heroicons-chart-pie"
            class="h-5 w-5"
          />
        </UButton>
      </UTooltip>

      <UTooltip
        text="Notifications"
        :shortcuts="['N']"
      >
        <UButton
          color="gray"
          variant="ghost"
          square
          @click="toggleNotificationsSlideover"
        >
          <div v-if="newNotificationsToShow">
            <UChip
              color="red"
              inset
            >
              <UIcon
                name="i-heroicons-bell-alert"
                class="h-5 w-5"
              />
            </UChip>
          </div>
          <UIcon
            v-else
            name="i-heroicons-bell"
            class="h-5 w-5"
          />
        </UButton>
      </UTooltip>

      <UDropdown :items>
        <UButton
          icon="i-heroicons-plus"
          color="green"
          size="md"
          class="ml-1.5 rounded-full"
        />
      </UDropdown>
    </template>
  </UDashboardNavbar>
</template>

<script setup>
const props = defineProps({
  title: {
    type: String,
    default: "Home",
  },
  badge: {
    type: (String, Number),
    default: 0,
  },
  showFilter: {
    type: Boolean,
    default: false,
  },
  filterLabel: {
    type: String,
    default: "users",
  },
});
const q = ref("");
const filterSearch = ref("");
// focus on ref="filterSearch" when '/' is pressed
defineShortcuts({
  "/": () => {
    filterSearch.value?.input?.focus();
  },
});

const emit = defineEmits(["update:modelValue"]);
watch(q, () => {
  emit("update:modelValue", q.value);
});

const { isStatsCardSlideoverOpen, isNotificationsSlideoverOpen } =
  useDashboard();

const { data: notifications } = await useAsyncData(
  "notifications",
  async () => {
    const response = await useApiFetch("notifications");
    return response;
  },
);

const newNotificationsToShow = computed(() => {
  if (!notifications.value || !Array.isArray(notifications.value)) {
    return false;
  }

  return notifications.value.some(
    (notification) => notification.read_at === null,
  );
});

watch(
  () => notifications.value?.data,
  (newVal) => {},
  { deep: true },
);

import {
  ModalCompanyCreate,
  ModalDealCreate,
  ModalProjectCreate,
  ModalInstitutionCreate,
  ModalUserCreate,
} from "#components";
const modal = useModal();
const toast = useToast();

function openCompanyCreateModal() {
  modal.open(ModalCompanyCreate, {
    onError: (error) => {
      toast.add({ title: error.message, color: "red" });
    },
    onClose: () => {
      modal.close();
    },
    onCreated: () => {
      modal.close();
      toast.add({ title: "Company created", color: "green" });
    },
  });
}

function openDealCreateModal() {
  modal.open(ModalDealCreate, {
    onError: (error) => {
      toast.add({ title: error.message, color: "red" });
    },
    onClose: () => {
      modal.close();
    },
    onCreated: () => {
      modal.close();
      toast.add({ title: "Deal created", color: "green" });
    },
  });
}

function openInstitutionCreateModal() {
  modal.open(ModalInstitutionCreate, {
    onError: (error) => {
      toast.add({ title: error.message, color: "red" });
    },
    onClose: () => {
      modal.close();
    },
    onCreated: () => {
      modal.close();
      toast.add({ title: "Institution created", color: "green" });
    },
  });
}

function openProjectCreateModal() {
  modal.open(ModalProjectCreate, {
    onError: (error) => {
      toast.add({ title: error.message, color: "red" });
    },
    onClose: () => {
      modal.close();
    },
    onCreated: () => {
      modal.close();
      toast.add({ title: "Project created", color: "green" });
    },
  });
}

function openUserCreateModal() {
  modal.open(ModalUserCreate, {
    onClose: () => {
      modal.close();
    },
    onError: (error) => {
      toast.add({ title: error.message, color: "red" });
    },
    onCreated: () => {
      modal.close();
      toast.add({ title: "User created", color: "green" });
    },
  });
}

const items = [
  [
    {
      label: "New user",
      icon: "i-heroicons-user-plus",
      click: () => openUserCreateModal(),
    },
    {
      label: "New company",
      icon: "i-heroicons-building-office-2",
      click: () => openCompanyCreateModal(),
    },
    {
      label: "New deal",
      icon: "i-heroicons-currency-dollar",
      click: () => openDealCreateModal(),
    },
    {
      label: "New Institution",
      icon: "i-heroicons-building-library",
      to: "/institutions",
      click: () => openInstitutionCreateModal(),
    },
    {
      label: "New project",
      icon: "i-heroicons-clipboard-document-list",
      to: "/projects",
      click: () => openProjectCreateModal(),
    },
  ],
];

// dynamic breadcrumbs based on route
const links = ref([{ label: "Home", icon: "i-heroicons-home", to: "/" }]);

// if path or props.title does not contain 'home', push to links array
// change into an array, iterate, and push to links array
if (props.title.toLowerCase() !== "home") {
  links.value.push({ label: props.title, to: `/${props.title.toLowerCase()}` });
}

const toggleNotificationsSlideover = () => {
  isNotificationsSlideoverOpen.value = !isNotificationsSlideoverOpen.value;
};

const toggleStatsCardSlideover = () => {
  isStatsCardSlideoverOpen.value = !isStatsCardSlideoverOpen.value;
};
</script>
