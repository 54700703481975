<template>
  <UModal>
    <UCard>
      <template #header>
        <h2 class="text-lg font-semibold">
          Add a document to {{ data.title }}
        </h2>
      </template>

      <fieldset class="space-y-4">
        <UFormGroup label="Document category">
          <Multiselect
            v-model="document.document_category"
            :options="documentCategories.data"
            label="title"
            track-by="title"
            :multiple="false"
            placeholder="Select document category"
            group-values="subcategories"
            group-label="title"
          />
        </UFormGroup>

        <div v-if="documentHasExpiryDate" class="flex justify-between">
          <UFormGroup label="Expiry date" required>
            <UInput
              type="date"
              v-model="document.expires_at"
              :required="true"
            />
          </UFormGroup>

          <UFormGroup label="Signed at" required>
            <UInput
              id="document.signed_at"
              v-model="document.signed_at"
              label="Date signed"
              type="date"
              :required="true"
            />
          </UFormGroup>
        </div>

        <UFormGroup label="Document description" name="title">
          <UInput v-model="document.description" />
        </UFormGroup>

        <UFormGroup label="Document" name="document">
          <DropzoneUpload
            class="drop-area"
            @files-dropped="addFiles"
            #default="{ dropZoneActive }"
          >
            <label for="file-input">
              <span v-if="dropZoneActive">
                <span>Drop Them Here</span>
                <span class="smaller">to add them</span>
              </span>
              <span v-else>
                <span>Drag Your Files Here</span>
                <span class="smaller">
                  or <strong><em>click here</em></strong> to select files
                </span>
              </span>

              <input
                type="file"
                id="file-input"
                multiple
                @change="onInputChange"
              />
            </label>
            <ul class="image-list" v-show="files.length">
              <FilePreview
                v-for="file of files"
                :key="file.id"
                :file="file"
                tag="li"
                @remove="removeFile"
              />
            </ul>
          </DropzoneUpload>
        </UFormGroup>
      </fieldset>

      <template #footer>
        <div class="flex justify-between">
          <UButton
            @click="$emit('close')"
            color="red"
            variant="ghost"
            label="Cancel"
          />
          <UButton
            color="green"
            @click="uploadDocuments"
            label="Upload"
            trailingIcon="i-heroicons-cloud-arrow-up"
          />
        </div>
      </template>
    </UCard>
  </UModal>
</template>

<script setup>
import Multiselect from "vue-multiselect";
const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const document = ref({
  description: null,
  document_category: {},
  signed_at: null,
  expires_at: null,
});

const documentCategories = await useApiDropdownFetch("document-categories");

const documentHasExpiryDate = computed(() => {
  const expiryDateSlugs = ["nda"];

  return !!(
    document.value.document_category &&
    expiryDateSlugs.includes(document.value.document_category.slug)
  );
});

// File Management
import useFileList from "@/utils/fileList";
const { files, addFiles, removeFile } = useFileList();

function onInputChange(e) {
  addFiles(e.target.files);
  e.target.value = null; // reset so that selecting the same file again will still cause it to fire this change
}

// Uploader
import createUploader from "@/utils/fileUploader";
const { uploadFiles } = createUploader(
  `projects/${props.data.project.id}/documents`,
);

const emit = defineEmits(["uploaded", "close", "error"]);

async function uploadDocuments() {
  try {
    const response = await uploadFiles(files.value, document.value);
    if (response.success) {
      emit("uploaded");
    }
  } catch (error) {
    emit("error", error);
  }
}
</script>
