<template>
  <UModal :ui="{ width: 'w-full sm:max-w-6xl' }">
    <UCard>
      <template #header> Create new user </template>

      <fieldset class="space-y-4">
        <UFormGroup label="User Type">
          <Multiselect
            v-model="user.types"
            :options="userTypes.data"
            label="title"
            track-by="id"
            :multiple="true"
            placeholder="Select user type"
          />
        </UFormGroup>
        <div class="grid grid-cols-2 gap-4">
          <UFormGroup
            label="Contact name"
            required
            :error="nameError"
          >
            <UInput
              v-model="user.name"
              @blur="validateName"
              :trailing-icon="
                nameError
                  ? 'i-heroicons-exclamation-triangle-20-solid'
                  : undefined
              "
            />
          </UFormGroup>

          <UFormGroup
            label="Default (login) email"
            required
            :error="emailError"
          >
            <UInput
              v-model="user.email"
              @blur="validateEmail"
              :trailing-icon="
                emailError
                  ? 'i-heroicons-exclamation-triangle-20-solid'
                  : undefined
              "
            />
          </UFormGroup>
        </div>

        <div class="grid grid-cols-4 gap-4">
          <UFormGroup
            label="City"
            name="address.city"
          >
            <UInput v-model="user.address.city" />
          </UFormGroup>

          <UFormGroup
            label="State"
            name="address.state"
          >
            <UInput v-model="user.address.state" />
          </UFormGroup>

          <UFormGroup
            label="Country"
            name="address.country"
          >
            <UInput v-model="user.address.country" />
          </UFormGroup>

          <UFormGroup
            label="Zip"
            name="address.zip"
          >
            <UInput v-model="user.address.zip" />
          </UFormGroup>
        </div>

        <div class="grid grid-cols-2 gap-4">
          <UFormGroup label="FastPeopleSearch Link">
            <UInput
              v-model="user.fps_link"
              placeholder="FPS link"
            />
          </UFormGroup>

          <UFormGroup label="LinkedIn Link">
            <UInput
              v-model="user.linked_in_link"
              placeholder="LinkedIn link"
            />
          </UFormGroup>
        </div>
      </fieldset>

      <template #footer>
        <div class="flex justify-between">
          <UButton
            color="gray"
            label="Cancel"
            @click="$emit('close')"
          />

          <UButton
            color="green"
            label="Create"
            @click="createUser"
          />
        </div>
      </template>
    </UCard>
  </UModal>
</template>

<script setup>
import Multiselect from "vue-multiselect";

const userTypes = await useApiDropdownFetch("user-types");
const emailError = ref("");
const nameError = ref("");

const user = ref({
  types: [],
  name: null,
  email: null,
  address: {
    city: null,
    state: null,
    country: null,
    zip: null,
  },
  contacts: {
    emails: [
      {
        email: "",
        type: "work",
        default: false,
      },
    ],
    phones: [
      {
        phone: "",
        type: "work",
        default: true,
      },
    ],
  },
  fps_link: null,
  linked_in_link: null,
});

const validateEmail = () => {
  if (!user.value.email || user.value.email.trim() === "") {
    emailError.value = "You must enter an email";
    return false;
  }
  emailError.value = "";
  return true;
};

const validateName = () => {
  if (!user.value.name || user.value.name.trim() === "") {
    nameError.value = "A name is required";
    return false;
  }
  nameError.value = "";
  return true;
};

watch(
  () => user.value.email,
  (newValue) => {
    if (newValue) {
      emailError.value = "";
    }
  },
);

watch(
  () => user.value.name,
  (newValue) => {
    if (newValue) {
      nameError.value = "";
    }
  },
);

const validateAll = () => {
  const nameValid = validateName();
  const emailValid = validateEmail();
  return nameValid && emailValid;
};

const emit = defineEmits(["created", "error", "close"]);
async function createUser() {
  if (!validateAll()) {
    return;
  }

  try {
    const response = await useApiPost("users", {
      body: user.value,
    });

    if (response.success) {
      user.value = {
        types: [],
        name: null,
        email: null,
        address: {
          city: null,
          state: null,
          country: null,
          zip: null,
        },
        contacts: {
          emails: [
            {
              email: null,
              type: "work",
            },
          ],
          phones: [
            {
              phone: null,
              type: "work",
            },
          ],
        },
        fps_link: null,
        linked_in_link: null,
      };
      emit("created");
    }
  } catch (error) {
    emit("error", error);
  }
}
</script>
