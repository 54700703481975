// https://pasteworks.sentry.io/projects/admin-next/getting-started/?product=performance-monitoring&product=session-replay
// https://docs.sentry.io/platforms/javascript/guides/nuxt/
import * as Sentry from "@sentry/nuxt";

Sentry.init({
  // If set up, you can use your runtime config here
  dsn: process.env.SENTRY_DSN,
  integrations: [
    Sentry.replayIntegration({
      // Additional SDK configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  release: process.env.CI_COMMIT_SHA,
  environment: process.env.SENTRY_ENVIRONMENT,
  // Tracing
  // We recommend adjusting this value in production, or using a tracesSampler for finer control.
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^([a-zA-Z0-9-]+\.)*pasteworks\.com/],
  // Session Replay
  replaysSessionSampleRate: 0.33, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

/**
 * sentry v2 config: {
    sourceMapStyle: 'hidden-source-map',
    publishRelease: true,
    tracing: {
      tracesSampleRate: 0.1,
      vueOptions: {
        tracing: true,
        tracingOptions: {
          hooks: ['mount', 'update'],
          timeout: 2000,
          trackComponents: true,
        },
      },
      browserOptions: {},
    },
  },
 */
