<template>
  <UModal v-model="isOpen" prevent-close>
    <UCard>
      <template #header> Create new company </template>

      <UFormGroup label="Import ticker(s) (comma separated)">
        <UInput
          v-model="company.tickers"
          placeholder="This currently does not work!"
        />
      </UFormGroup>

      <UDivider label="OR" />

      <UFormGroup label="Company Name" :error="nameError" required>
        <UInput
          v-model="company.name"
          :required="true"
          @blur="validateName"
          :trailing-icon="
            nameError ? 'i-heroicons-exclamation-triangle-20-solid' : undefined
          "
        />
      </UFormGroup>

      <UFormGroup label="Company Type" required :error="companyTypeError">
        <multiselect
          v-model="company.company_type"
          :options="companyType.data"
          label="title"
          track-by="title"
          placeholder="Select company type"
          @blur="validateCompanyType"
        />
      </UFormGroup>

      <UFormGroup label="Finance Stage" required :error="financeStageError">
        <multiselect
          v-model="company.finance_stage"
          :options="financeStage.data"
          label="title"
          track-by="title"
          placeholder="Select finance stage"
          @blur="validateFinanceStage"
        />
      </UFormGroup>

      <UFormGroup
        v-if="company.finance_stage?.slug === 'public'"
        label="Ticker"
        name="ticker"
      >
        <UInput v-model="company.ticker" />
      </UFormGroup>

      <UFormGroup label="Industries">
        <multiselect
          v-model="company.industries"
          :options="industries.data"
          label="title"
          track-by="id"
          placeholder="Choose industries"
          :multiple="true"
          group-values="subindustries"
          group-label="title"
        />
      </UFormGroup>

      <UFormGroup label="City" name="city">
        <UInput v-model="company.address.city" />
      </UFormGroup>

      <UFormGroup label="State" name="state">
        <UInput v-model="company.address.state" />
      </UFormGroup>

      <UFormGroup label="Country" name="country">
        <UInput v-model="company.address.country" />
      </UFormGroup>

      <UFormGroup label="Website" name="website">
        <UInput v-model="company.links.website" />
      </UFormGroup>

      <UFormGroup label="Dataroom link" name="dataroom">
        <UInput v-model="company.links.dataroom" />
      </UFormGroup>

      <UFormGroup label="Description">
        <UTextarea v-model="company.description" />
      </UFormGroup>

      <template #footer>
        <div class="flex justify-between">
          <UButton color="gray" @click="$emit('close')" label="Cancel" />
          <UButton color="green" @click="validateAndCreate">Create</UButton>
        </div>
      </template>
    </UCard>
  </UModal>
</template>

<script setup>
import Multiselect from "vue-multiselect";

const isOpen = ref(false);
const nameError = ref("");
const companyTypeError = ref("");
const financeStageError = ref("");

const company = ref({
  name: null,
  company_type: null,
  finance_stage: null,
  industries: [],
  tickers: [],
  ticker: null,
  address: [
    {
      city: null,
      state: null,
      country: null,
    },
  ],
  links: {
    website: null,
    dataroom: null,
  },
  description: null,
});

const companyType = await useApiDropdownFetch("company-types");
const financeStage = await useApiDropdownFetch("finance-stages");
const industries = await useApiDropdownFetch("industries");

const validateName = () => {
  if (!company.value.name || company.value.name.trim() === "") {
    nameError.value = "Company name is required";
    return false;
  }
  nameError.value = "";
  return true;
};

const validateCompanyType = () => {
  if (!company.value.company_type) {
    companyTypeError.value = "Company type is required";
    return false;
  }
  companyTypeError.value = "";
  return true;
};

const validateFinanceStage = () => {
  if (!company.value.finance_stage) {
    financeStageError.value = "Finance stage is required";
    return false;
  }
  financeStageError.value = "";
  return true;
};

watch(
  () => company.value.name,
  (newValue) => {
    if (newValue) {
      nameError.value = "";
    }
  },
);

watch(
  () => company.value.company_type,
  (newValue) => {
    if (newValue) {
      companyTypeError.value = "";
    }
  },
);

watch(
  () => company.value.finance_stage,
  (newValue) => {
    if (newValue) {
      financeStageError.value = "";
    }
  },
);

const validateAll = () => {
  const nameValid = validateName();
  const typeValid = validateCompanyType();
  const stageValid = validateFinanceStage();
  return nameValid && typeValid && stageValid;
};

const emit = defineEmits(["created", "error", "close"]);

async function validateAndCreate() {
  if (!validateAll()) {
    return;
  }

  const endpoint = company.value.tickers.length
    ? "companies-import-tickers"
    : "companies";

  try {
    const response = await useApiPost(endpoint, {
      body: company.value,
    });

    if (response.success) {
      company.value = {
        name: null,
        company_type: null,
        finance_stage: null,
        industries: [],
        tickers: [],
        ticker: null,
        address: [
          {
            city: null,
            state: null,
            country: null,
          },
        ],
        links: {
          website: null,
          dataroom: null,
        },
        description: null,
      };
      emit("created");
    }
  } catch (error) {
    emit("error", error);
  }
}
</script>
